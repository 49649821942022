import { useNavigate } from "@tanstack/react-router";
import type { TProfileSectionItem } from "../common";
import { NavigationBackMobile } from "./navigation-back.mobile";

interface IProfileSectionMobileProps {
	isActive: boolean;
	currentSection: TProfileSectionItem | undefined;
}

export function ProfileSectionMobile({
	isActive,
	currentSection,
}: IProfileSectionMobileProps) {
	const navigate = useNavigate();

	if (!currentSection) {
		return null;
	}

	return (
		<div
			id="profile-section-mobile"
			className="flex flex-col min-w-0 h-full overflow-y-auto scrollbar-thin transition-opacity duration-200"
			style={{
				opacity: Number(isActive),
			}}
		>
			<NavigationBackMobile
				className="mb-5"
				onClick={() =>
					navigate({
						// @ts-ignore
						search: (prev) => ({ ...prev, profileSection: undefined }),
					})
				}
			>
				{currentSection.title}
			</NavigationBackMobile>
			{currentSection.component}
		</div>
	);
}
