import { LanguageSelector } from "@features/language-selector";
import { MobileMenuButton } from "@features/mobile-menu-button";
import { PlayerInfo } from "@features/player";
import { ThemeSwitch } from "@features/theme-switch";
import { useAuth, useModal } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Button } from "@shared/ui/button";
import { Logo } from "@shared/ui/logo";
import { Link } from "@tanstack/react-router";
import { useHeaderCategories } from "../lib";
import { NavigationList } from "./components";

export function Header() {
  const { $t } = useTranslate();
  const { isAuth } = useAuth();
  const { open: openLogInModal } = useModal("log-in");
  const headerCategories = useHeaderCategories();

  return (
    <header className="sticky top-0 z-10 bg-mercury lg:h-17.5 dark:bg-outer-space">
      <div className="mx-auto flex h-full mobile-only:flex-col justify-between lg:max-w-screen-3xl lg:px-5">
        <nav className="scrollbar-thin mobile-only:order-2 flex lg:h-full lg:items-center lg:overflow-x-auto">
          <div className="mobile-only:hidden">
            <Link to="/">
              <Logo className="h-11" />
            </Link>
          </div>
          <NavigationList items={headerCategories} />
        </nav>
        <div className="mobile-only:order-1 flex items-center mobile-only:justify-between mobile-only:p-3 lg:h-full lg:gap-5">
          <div className="flex items-center gap-2.5 lg:hidden">
            <MobileMenuButton />
            <Link to="/">
              <Logo className="h-7" />
            </Link>
          </div>

          <LanguageSelector className="mobile-only:hidden" />
          <ThemeSwitch className="mobile-only:hidden" />
          <div className="flex h-full items-center gap-5 lg:contents">
            {isAuth ? (
              <PlayerInfo />
            ) : (
              <Button onClick={openLogInModal}> {$t({ defaultMessage: "sign in" })}</Button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
