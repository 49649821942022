import { memo, useState } from "react";

import { useMobile } from "@lobby/core/shared";
import { clsx } from "clsx";
import {
  AddCardHint,
  BackToLobbyLink,
  BarcodeCard,
  BlankCard,
  LogInWithBarcodeButton,
} from "./components";

export const MemberCard = memo(function MemberCard() {
  const [isCardExpanded, setIsCardExpanded] = useState(false);
  const isMobile = useMobile();

  return (
    <div className="px-2">
      <div
        className={`relative mx-auto flex w-full max-w-[25rem] select-none flex-col items-center transition-[aspect-ratio] ${isCardExpanded ? "[aspect-ratio:1/1.2]" : "[aspect-ratio:1.3/1]"}`}
      >
        <BarcodeCard />
        <BlankCard onClick={() => setIsCardExpanded((v) => !v)} />
      </div>

      <div
        className={clsx("relative mt-10 flex flex-col items-center text-center", {
          hidden: isCardExpanded && !isMobile,
        })}
      >
        <LogInWithBarcodeButton onClick={() => setIsCardExpanded((v) => !v)} />
        <BackToLobbyLink />
        <AddCardHint className="lg:hidden" />
      </div>
    </div>
  );
});
