import { Player, usePlayerMoneyFormatter } from "@lobby/core/entities";
import { useModal } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { Modal } from "@shared/ui/modal";
import { SVGIcon } from "@shared/ui/svg-icon";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

export function TakeBonusModal() {
  const { isOpen, close } = useModal("take-bonus");

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={close} />
      <TakeBonusComponent />
    </Modal>
  );
}

function TakeBonusComponent() {
  const { $t } = useTranslate();
  const navigate = useNavigate();
  const { close: closeModal } = useModal("take-bonus");
  const formatMoney = usePlayerMoneyFormatter();

  const { data } = Player.useBalances();
  const bonus = data?.bonus as {
    amount: number;
    type: string;
  };
  const activateBonusMutation = Player.useActivateBonus();

  const amount = formatMoney(bonus?.amount);

  function handleActivateBonus() {
    activateBonusMutation.mutate();
    closeModal();
  }

  useEffect(() => {
    if (!bonus) {
      navigate({
        // @ts-ignore
        search: (prev) => ({ ...prev, modal: undefined }),
        replace: true,
      });
    }
  }, [bonus, activateBonusMutation, navigate]);

  return (
    <Modal.Panel className="lg:min-w-[18.75rem] bg-yellow-orange bg-opacity-30 rounded-t-[23.75rem] border-1 border-yellow-orange p-2.5 top-10 relative w-fit max-w-full">
      <div className="lg:pt-12.5 lg:pb-10 lg:px-7.5 bg-gradient-to-t from-[#ffb23f] to-burnt-sienna rounded-inherit px-8 pb-5">
        <div className="lg:w-60 lg:h-72 absolute -top-8 left-1/2 -translate-x-1/2 w-44 drop-shadow-[0.5rem_0.5rem_0_rgba(0,0,0,0.25)]">
          <SVGIcon name="bonusStamp" />
        </div>
        <div className="lg:pt-52 flex flex-col items-center pt-44">
          <div className="font-extrabold text-4xl italic uppercase font-commissioner tracking-wider text-white [text-shadow:0.25rem_0.25rem_0_rgba(0,0,0,0.3)]">
            {$t({ defaultMessage: "Bonus" })}
            {skipIntl("!")}
          </div>
          <div className="font-bold text-2xl text-white mt-5 uppercase">{bonus?.type}</div>
          <div className="font-extrabold text-4xl italic uppercase font-commissioner tracking-wider text-white [text-shadow:0.25rem_0.25rem_0_rgba(0,0,0,0.3)] mt-2.5">
            {amount}
          </div>
          <div className="lg:grid-cols-2 grid mobile-only:grid-rows-2 gap-5 mt-5">
            <button
              className="bg-white rounded-rounded text-sm text-ebony-clay font-bold uppercase px-7.5 h-9 whitespace-nowrap [box-shadow:0.5rem_0.5rem_0_rgba(0,0,0,0.25)] hover:text-keppel active:translate-x-[0.5rem] active:translate-y-[0.5rem] active:[box-shadow:none]"
              type="button"
              onClick={closeModal}
            >
              {$t({ defaultMessage: "later" })}
            </button>
            <button
              className="bg-java rounded-rounded text-sm text-ebony-clay font-bold uppercase px-7.5 h-9 whitespace-nowrap [box-shadow:0.5rem_0.5rem_0_rgba(0,0,0,0.25)] hover:text-white active:translate-x-[0.5rem] active:translate-y-[0.5rem] active:[box-shadow:none]"
              type="button"
              onClick={handleActivateBonus}
            >
              {$t({ defaultMessage: "take" })}
            </button>
          </div>
        </div>
      </div>
    </Modal.Panel>
  );
}
