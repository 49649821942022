import { LanguageSelector } from "@features/language-selector";
import { LoginForm } from "@features/log-in-form";
import { ThemeSwitch } from "@features/theme-switch";
import { Lobby } from "@lobby/core/entities/lobby";
import { useAuth, useModal } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Button } from "@shared/ui";
import { Logo } from "@shared/ui/logo";
import { Modal } from "@shared/ui/modal";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

export function LogInModal({ isStatic = false }: { isStatic?: boolean }) {
	const { isOpen, close } = useModal("log-in");

	return (
		<Modal open={isOpen}>
			<Modal.Overlay onClick={isStatic ? undefined : close} />
			<LogInModalComponent />
		</Modal>
	);
}

function LogInModalComponent() {
	const { open: openRegisterModal } = useModal("register");
	const navigate = useNavigate();
	const { $t } = useTranslate();

	const { isAuth } = useAuth();
	const { data: lobbyData } = Lobby.useLobby();

	const isRegistrationEnabled = lobbyData?.registration.enabled ?? false;

	useEffect(() => {
		if (isAuth) {
			navigate({
				to: "/",
				replace: true,
			});
		}
	}, [isAuth]);

	return (
		<Modal.Panel className="mobile-only:min-h-full w-full border-keppel bg-mercury p-5 lg:top-17 lg:w-[28.75rem] lg:rounded-rounded lg:border-1 dark:border-java dark:bg-ebony-clay">
			<Logo className="mx-auto h-10 lg:h-11" />
			<div className="mt-5 flex-c-sb gap-5">
				<div className="font-bold text-lg uppercase lg:text-xl">
					{$t({ defaultMessage: "Log In" })}
				</div>
				{isRegistrationEnabled && (
					<Button onClick={openRegisterModal}>
						{$t({ defaultMessage: "Sign up" })}
					</Button>
				)}
			</div>

			<LoginForm className="mt-5 mobile-only:mb-10" />

			<div className="mt-auto flex items-center justify-between lg:mt-20">
				<LanguageSelector orientation="top" />
				<ThemeSwitch />
			</div>
		</Modal.Panel>
	);
}
