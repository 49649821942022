import { useModal } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Modal } from "@shared/ui/modal";
import { useIsMutating } from "@tanstack/react-query";
import { PasswordResetForm } from "./components";

export function PasswordResetModal({ isStatic = false }: { isStatic?: boolean }) {
  const { isOpen, close } = useModal("password-reset");

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={isStatic ? undefined : close} />
      <PasswordResetModalComponent />
    </Modal>
  );
}

function PasswordResetModalComponent() {
  const { $t } = useTranslate();

  const isPending = useIsMutating({ mutationKey: ["Email.setNewPassword"] }) > 0;

  return (
    <Modal.Panel className="w-full border-keppel bg-mercury p-5 lg:top-17 lg:w-[35rem] lg:rounded-rounded lg:border-1 dark:border-java dark:bg-ebony-clay">
      <p className="text-center font-bold text-xl uppercase">
        {$t({ defaultMessage: "Reset your password" })}
      </p>
      <p className="text-center">{$t({ defaultMessage: "Enter a new password below" })}</p>

      <div className="mt-5">
        <PasswordResetForm />
      </div>

      <Modal.PendingOverlay isActive={isPending} />
    </Modal.Panel>
  );
}
