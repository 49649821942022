import { clsx } from "clsx";
import { SVGIcon } from "../svg-icon";
import type { ReactNode } from "react";

interface IButtonProps {
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  type?: "primary" | "primary-alternate" | "danger" | "warning" | "default";
  htmlType?: "button" | "submit" | "reset";
  loading?: boolean;
  onClick?: VoidFunction;
}

export function Button({
  className,
  children,
  disabled,
  type,
  htmlType = "button",
  loading,
  onClick,
}: IButtonProps) {
  let buttonTypeClass = "";
  switch (type) {
    case "primary":
      buttonTypeClass = "btn_primary";
      break;
    case "primary-alternate":
      buttonTypeClass = "btn_primary-alternate";
      break;
    case "danger":
      buttonTypeClass = "btn_danger";
      break;
    case "warning":
      buttonTypeClass = "btn_warning";
      break;
    default:
      buttonTypeClass = "btn_default";
  }

  return (
    <button
      className={clsx(
        "btn",
        buttonTypeClass,
        loading && "pointer-events-none cursor-default",
        className,
      )}
      type={htmlType}
      disabled={disabled}
      onClick={onClick}
    >
      <span className={`inline-block ${loading ? "invisible" : "visible"}`}>{children}</span>
      {loading && (
        <span className="absolute animate-spin">
          <SVGIcon name="loading" />
        </span>
      )}
    </button>
  );
}
