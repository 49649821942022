import { useTranslate } from "@lobby/ocb-intl";
import { Skeleton } from "@shared/ui";

interface IRulesSectionProps {
  rules?: { general: string; daily: string };
}

function RuleContent({ title, description }: { title: string; description: string | undefined }) {
  return (
    <div className="bg-keppel/30 rounded-rounded p-2.5 w-80 isolate dark:bg-java/30">
      <div className="bg-alto rounded-inherit px-7.5 py-5 dark:bg-outer-space">
        <div className="font-bold text-xl mb-2.5 uppercase dark:text-java">{title}</div>
        {description ? (
          <p className="whitespace-pre-line leading-5">{description}</p>
        ) : (
          <div className="flex flex-col gap-1.5 w-full *:h-5 *:w-full">
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
      </div>
    </div>
  );
}

export function RulesSectionDesktop({ rules }: IRulesSectionProps) {
  const { $t } = useTranslate();

  return (
    <div className="flex-c-sb absolute w-[100rem] max-w-full pointer-events-none">
      <RuleContent title={$t({ defaultMessage: "how it works" })} description={rules?.general} />
      <RuleContent title={$t({ defaultMessage: "daily wheel rules" })} description={rules?.daily} />
    </div>
  );
}
