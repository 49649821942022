import { Player } from "@lobby/core/entities";
import { Lobby } from "@lobby/core/entities/lobby";
import { useTranslate } from "@lobby/ocb-intl";
import { Button } from "@shared/ui";

interface ILogoutButtonProps {
  className?: string;
}

export function LogoutButton({ className }: ILogoutButtonProps) {
  const { $t } = useTranslate();
  const logoutMutation = Player.useLogOut();
  const lobby = Lobby.useLobby();
  const isLogoutButtonEnabled = lobby?.data?.buttons?.logout;

  return (
    isLogoutButtonEnabled && (
      <div className={className}>
        <Button className="w-full" onClick={logoutMutation.mutate}>
          {$t({ defaultMessage: "Sign out" })}
        </Button>
      </div>
    )
  );
}
