import { clsx } from "clsx";
import type { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { Spin } from "../spin";
import { SVGIcon } from "../svg-icon";

import "./styles.css";

function Portal({ children }: PropsWithChildren) {
  return <>{createPortal(children, document.getElementById("root")!)}</>;
}

function Overlay({ className = "", onClick }: { className?: string; onClick?: VoidFunction }) {
  return (
    <div
      className={clsx(
        className,
        "fixed inset-0 bg-mercury dark:bg-infinity bg-opacity-80 dark:bg-opacity-80",
      )}
      onClick={onClick}
    />
  );
}

function PendingOverlay({ isActive }: { isActive: boolean }) {
  return (
    <div
      className={clsx(
        "rounded-inherit absolute inset-0 overflow-hidden",
        isActive ? "visible" : "invisible",
      )}
    >
      <div className="bg-white dark:bg-bright-gray bg-opacity-60 dark:bg-opacity-60 absolute inset-0" />
      <div className="absolute inset-0 flex-center">
        <Spin />
      </div>
    </div>
  );
}

function Title({ className, children }: PropsWithChildren<{ className?: string }>) {
  return <div className={clsx("modal-dialog__title", className)}>{children}</div>;
}

function CloseButton({ className, onClick }: { className?: string; onClick: VoidFunction }) {
  return (
    <button
      className={clsx(className, "block absolute right-0 h-4 w-5 lg:size-8")}
      type="button"
      onClick={onClick}
    >
      <SVGIcon className="text-keppel dark:text-java size-full" name="close" />
    </button>
  );
}

function Panel({
  className,
  centered,
  children,
}: PropsWithChildren<{ className?: string; centered?: boolean }>) {
  return (
    <div
      className={clsx(
        "modal-dialog__panel flex flex-col pointer-events-auto absolute left-1/2 -translate-x-1/2",
        className,
        centered && "top-1/2 -translate-y-1/2",
      )}
    >
      {children}
    </div>
  );
}

function ModalBase({
  children,
  open,
}: PropsWithChildren<{
  open: boolean;
}>) {
  return (
    open && (
      <Portal>
        <div className="modal-dialog lg:overflow-y-scroll fixed overflow-x-hidden scrollbar-thin inset-0 z-[999]">
          {children}
        </div>
      </Portal>
    )
  );
}

export const Modal = Object.assign(ModalBase, {
  Title,
  Panel,
  Overlay,
  PendingOverlay,
  Portal,
  CloseButton,
});
