import type { ApiClientResponse } from "@lobby/api-client";
import { Game } from "@lobby/core/entities";

type TGames = ApiClientResponse<"Game.getNoWagerList">["result"]["data"];

export const useNoWagerGames = () => {
  const { data: noWagerGames, isLoading: isNoWagerLoading } = Game.useNoWagerGames();
  const { data: noJackpotGames, isLoading: isNoJackpotLoading } = Game.useNoJackpotGames();

  const filteredByProviders = (games?: TGames) => {
    if (!games || !games?.length) return;

    return games.reduce(
      (acc, game) => {
        if (!acc[game.providerName]) {
          acc[game.providerName] = [];
        }

        acc[game.providerName].push(game);

        return acc;
      },
      {} as Record<string, TGames>,
    );
  };

  return {
    noWagerGames: noWagerGames ? filteredByProviders(noWagerGames.data) : null,
    noJackpotGames: noJackpotGames ? filteredByProviders(noJackpotGames.data) : null,
    isNoWagerLoading,
    isNoJackpotLoading,
  };
};
