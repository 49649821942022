import { useAuth, useMobile, useModal } from "@lobby/core/shared";
import { Modal } from "@shared/ui";
import { useIsMutating } from "@tanstack/react-query";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useEffect } from "react";
import type { PropsWithChildren } from "react";
import { ProfileSection } from "../lib";
import { useProfileSections } from "./common";
import { ProfileComponentDesktop } from "./desktop";
import { ProfileComponentMobile } from "./mobile";

export function ProfileModal() {
	const { isOpen, close } = useModal("profile");

	return (
		<Modal open={isOpen}>
			<Modal.Overlay onClick={close} />
			<ProfileModalAuthGuard>
				<ProfileComponent />
			</ProfileModalAuthGuard>
		</Modal>
	);
}

function ProfileModalAuthGuard({ children }: PropsWithChildren) {
	const navigate = useNavigate();
	const { isAuth, isAuthInProgress } = useAuth();

	useEffect(() => {
		if (!isAuth && !isAuthInProgress) {
			navigate({
				// @ts-ignore
				search: (prev) => ({ ...prev, modal: "log-in" }),
				replace: true,
			});
		}
	}, [isAuth, isAuthInProgress, navigate]);

	return isAuth ? children : null;
}

function ProfileComponent() {
	const currentProfileSection = useSearch({
		strict: false,
		select: ({ profileSection }) => profileSection,
	});
	const isLogOutPending = useIsMutating({ mutationKey: ["Player.logOut"] }) > 0;

	const isMobile = useMobile();
	const { close } = useModal("profile");
	const profileSections = useProfileSections();

	const currentSection = profileSections.find(
		(section) => section.id === currentProfileSection,
	);

	return isMobile ? (
		<ProfileComponentMobile
			currentSection={currentSection}
			close={close}
			showLoadingOverlay={isLogOutPending}
		/>
	) : (
		<ProfileComponentDesktop
			currentSection={
				currentSection ||
				profileSections.find(
					(section) => section.id === ProfileSection.Bonuses,
				) ||
				profileSections[0]
			}
			close={close}
			showLoadingOverlay={isLogOutPending}
		/>
	);
}
