import { validateSearchProfileSection } from "@app/lib";
import { SuccessModal } from "@features/success-modal";
import { WarningModal } from "@features/warning-modal";
import { ErrorModal } from "@shared/ui";
import type { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
	Navigate,
	Outlet,
	createRootRouteWithContext,
} from "@tanstack/react-router";
import { ErrorComponent } from "@widget/error-component";
import type { TProfileSection } from "@widget/profile-modal";

type GlobalSearch = {
	modal: string;
	cid: number | string;
	pid: number;
	game: string;
	redirect: string;
	profileSection: TProfileSection;
};

export const Route = createRootRouteWithContext<{
	queryClient: QueryClient;
}>()({
	component: RootComponent,
	onError: console.error,
	errorComponent: ErrorComponent,
	notFoundComponent: () => <Navigate to="/" replace />,
	validateSearch: (search): Partial<GlobalSearch> => ({
		modal: search.modal as string,
		cid: search.cid as number,
		pid: search.pid as number,
		game: search.game as string,
		redirect: search.redirect as string,
		profileSection: validateSearchProfileSection(search) as TProfileSection,
	}),
});

function RootComponent() {
	return (
		<>
			<SuccessModal />
			<WarningModal />
			<ErrorModal />
			<div id="message-container" />

			<Outlet />
			<ReactQueryDevtools buttonPosition="bottom-left" />
		</>
	);
}
