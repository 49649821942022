import { ProviderButton } from "@features/provider-button";
import { ResetProviderFilterButton } from "@features/reset-provider-filter-button";
import { Game } from "@lobby/core/entities/game";
import { moveItemToHead, range } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { Button } from "@shared/ui/button";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useMemo } from "react";

export function ProviderList() {
  const { $t } = useTranslate();
  const navigate = useNavigate();
  const pid = useSearch({ strict: false, select: ({ pid }) => pid as number });

  const { data, isPending } = Game.useProvidersList();

  const providerList = data?.result?.data;

  const sortedProviders = useMemo(
    () => moveItemToHead(pid, providerList || []),
    [providerList, pid],
  );

  return (
    <div className="rounded-xs lg:rounded-rounded bg-athens-gray dark:bg-outer-space">
      <div className="flex items-center p-3 lg:p-5">
        <div className="whitespace-nowrap lg:text-xl lg:mr-15 text-keppel font-bold text-xs uppercase mr-3 dark:text-java">
          {$t({ defaultMessage: "Providers" })}
        </div>
        <div className="lg:gap-5 lg:mr-5 lg:h-9.5 lg:justify-normal justify-center flex flex-wrap gap-3 mr-3 h-7 overflow-hidden">
          {isPending && range(8)((idx) => <ProviderButton.Skeleton key={idx} />)}
          {sortedProviders?.map(({ name, id, stringId }) => (
            <ProviderButton
              className="lg:w-52 lg:h-9.5 w-28 h-7"
              key={id}
              name={name}
              id={id}
              stringId={stringId}
              isActive={pid === id}
            />
          ))}
        </div>
        <div className="lg:gap-5 flex items-center gap-3">
          {Boolean(pid) && <ResetProviderFilterButton />}
          <div className={`ml-auto ${(data?.result?.total ?? 0) > 1 ? "visible" : "invisible"}`}>
            <Button
              className="px-4"
              onClick={() => {
                navigate({
                  replace: true,
                  // @ts-ignore
                  search: (prev) => ({
                    ...prev,
                    modal: "providers",
                  }),
                });
              }}
            >
              {skipIntl(". . .")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
