import { Lobby } from "@lobby/core/entities";
import { Skeleton } from "@shared/ui/skeleton";
import { useDesktopBanners } from "../lib/use-banner";
import { BannersListDesktop } from "./banners-list.desktop";

export function BannerPanelDesktop() {
  const { isPending } = Lobby.useBanner();
  const banners = useDesktopBanners();

  const isBannerEmpty = !banners || banners.length === 0;

  return (
    (isPending || !isBannerEmpty) && (
      <div className="[aspect-ratio:7.46]">
        <div className="group relative grid h-full grid-cols-3 gap-x-5">
          {isPending && (
            <>
              <Skeleton className="h-full bg-silver" />
              <Skeleton className="h-full bg-silver" />
              <Skeleton className="h-full bg-silver" />
            </>
          )}

          {!isBannerEmpty && <BannersListDesktop banners={banners} />}
        </div>
      </div>
    )
  );
}
